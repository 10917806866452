// @ts-check
import qs from "qs";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useOrganization } from "../OrganizationContext";
import { useApi } from "../shared/useApi";

/** @typedef {("active" | "archived")} DTOStatus */
/** @typedef {("Soft" | "Medium" | "Hard")} KmCompound */

/**
 * @typedef DTOProps
 * @property {string} [_id]
 * @property {string} [oid]
 * @property {DTOStatus} status
 * @property {string} value
 * @property {string} tireDimension
 * @property {string} tireRim
 * @property {boolean} isRainTire
 * @property {string} [dtoComplement]
 * @property {string} [casingReference]
 * @property {KmCompound} [kmCompound]
 */

/** @typedef {import("../shared/AuditHelpers").AuditedResource & DTOProps} DTO */

export const DTOS = "dtos";

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<DTO>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<DTO>>} */
export const useDTOsQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([DTOS, selectedOrganization, searchParams], async () => {
    const { limit = 0, skip = 0, sort = "value", ...query } = searchParams;
    const response = await api.get(`v1/dtos`, {
      searchParams: qs.stringify({ limit, skip, sort, ...query }),
    });
    const totalCount = Number(response.headers.get("X-Total-Count"));
    let list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

/** @type {(searchParams?: import("../shared/QueryHelpers").SearchParams<DTO>) => import("react-query").UseQueryResult<import("../shared/QueryHelpers").Page<DTO>>} */
export const useDTOsWithStockQuery = (searchParams = {}) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([DTOS, selectedOrganization, searchParams], async () => {
    const { limit = 0, skip = 0, ...query } = searchParams;
    const response = await api.get(`v1/dtos/all/dto-stocks`, {
      searchParams: qs.stringify({ limit, skip, ...query }),
    });

    const totalCount = Number(response.headers.get("X-Total-Count"));
    let list = await response.json();

    return {
      totalCount,
      list,
    };
  });
};

/** @type {(dtoId: string) => import("react-query").UseQueryResult<DTO>} */
export const useDTOByIdQuery = (dtoId) => {
  const api = useApi();
  const { selectedOrganization } = useOrganization();
  return useQuery([DTOS, selectedOrganization, dtoId], async () => {
    return api.get(`v1/dtos/${dtoId}`).json();
  });
};

/** @type {() => import("react-query").UseMutationResult<DTO[], unknown, DTO[]>} */
export const useUpsertManyDTOMutation = () => {
  const { mutateAsync: upsertDTO } = useUpsertDTOMutation();
  return useMutation(async (dtos) => {
    return Promise.all(dtos.map((dto) => upsertDTO(dto)));
  });
};

export const useFetchDTOs = () => {
  const api = useApi();
  return async (searchParams) => {
    return api.get(`v1/dtos`, { searchParams: qs.stringify(searchParams) }).json();
  };
};

/** @type {() => import("react-query").UseMutationResult<DTO, unknown, DTO>} */
export const useUpsertDTOMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (dto) => {
      // Check the tireDimension property against the regex
      const tireDimensionRegex = /^\d+\/\d+-\d+$/;

      if (dto.tireDimension && !tireDimensionRegex.test(dto.tireDimension)) {
        // eslint-disable-next-line no-throw-literal
        throw { code: "BAD_FORMAT", message: "Invalid tireDimension format" };
      }
      if (dto._id) {
        return api.patch(`v1/dtos/${dto._id}`, { json: dto }).json();
      }
      return api.post(`v1/dtos`, { json: dto }).json();
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(DTOS);
      },
    }
  );
};

/** @type {() => import("react-query").UseMutationResult<DTO, unknown, DTO>} */
export const useUpsertWithoutReplaceDTOMutation = () => {
  const api = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (dto) => {
      const existingDto = await api
        .get(`v1/dtos`, {
          searchParams: qs.stringify({ value: dto.value }),
        })
        .json();
      if (existingDto.length === 0) {
        // Check the tireDimension property against the regex
        const tireDimensionRegex = /^\d+\/\d+-\d+$/;
        if (dto.tireDimension) {
          if (!tireDimensionRegex.test(dto.tireDimension))
            // eslint-disable-next-line no-throw-literal
            throw { code: "BAD_FORMAT", message: "Invalid tireDimension format" };
        }

        if (dto._id) {
          return api.patch(`v1/dtos/${dto._id}`, { json: dto }).json();
        }
        return api.post(`v1/dtos`, { json: dto }).json();
      }
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(DTOS);
      },
    }
  );
};
